<style scoped>
 p { line-height: 34px; }
</style>
<template>
  <el-card header="本报表制度根据《中华人民共和国统计法》的有关规定制定">
    <div>
      <p>《中华人民共和国统计法》第七条规定：国家机关、企业事业单位和其他组织以及个体工商户和个人等统计调查对象，必须依照本法和国家有关规定，真实、准确、完整、及时地提供统计调查所需的资料，不得提供不真实或者不完整的统计资料，不得迟报、拒报统计资料。</p>
      <p>《中华人民共和国统计法》第九条规定：统计机构和统计人员对在统计工作中知悉的国家秘密、商业秘密和个人信息，应当予以保密。</p>
      <p>《中华人民共和国统计法》第十五条规定：统计调查表应当标明表号、制定机关、批准或者备案文号、有效期限等标志。</p>
      <p>对未标明前款规定的标志或者超过有效期限的统计调查表，统计调查对象有权拒绝填报；县级以上人民政府统计机构应当依法责令停止有关统计调查活动。</p>
      <p>本制度由横店影视文化产业集聚区管理委员会负责解释</p>
    </div>
  </el-card>
</template>
<script>
export default {
  name: 'zhidu'
}
</script>
