<template>
  <loading-content :loading="loading" :error="error">
    <el-alert v-if="user.sample.status === -1" title="您好，当前企业已停止报表填报的权限！不需要上报报表" type="error" style="margin-bottom: 10px;"></el-alert>
    <el-card>
      <div slot="header" class="report-title">
        <span class="t">待填报的报表</span>
        <el-button icon="el-icon-refresh" circle size="mini" @click="search" />
      </div>
      <el-table
        :data="list"
        style="width: 100%">
        <el-table-column
          label="期次"
          width="120">
          <round slot-scope="scope" :type="scope.row.round.type" :round="scope.row.round.round" />
        </el-table-column>
        <el-table-column
          prop="table.name"
          label="报表">
        </el-table-column>
        <el-table-column
          label="状态"
          width="120">
          <report-status slot-scope="scope" type="label" :report="scope.row.report.status" :review="scope.row.report.reviewStatus" />
        </el-table-column>
        <el-table-column label="截止日期" width="110">
          <date-time slot-scope="scope" :time="scope.row.round.endAt" />
        </el-table-column>
        <el-table-column label="操作" width="140">
          <template slot-scope="scope">
            <el-button v-if="scope.row.report.status > 0" type="text" size="medium" icon="el-icon-link" @click="$showReport(scope.row.report.id)">查看</el-button>
            <el-button v-if="scope.row.report.reviewStatus !== 1" type="text" size="medium" icon="el-icon-edit"  @click="goReport(scope.row.table.id, scope.row.round.id, scope.row.report.id)">{{scope.row.report.status === 1 ? '修改' : '填报'}}</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="changeCurrent"
        @size-change="changeSize"
        :current-page.sync="page.current"
        :page-sizes="[20, 40, 60, 100]"
        :page-size="page.size"
        layout="sizes, prev, pager, next, total"
        :total="page.total">
      </el-pagination>
    </el-card>
    <el-dialog
      title="请完善业务信息"
      :visible.sync="yewuDialog"
      width="30%"
      :show-close="yewuShowClose"
      :close-on-press-escape="yewuShowClose"
      :close-on-click-modal="false"
      >
      <el-form ref="form" hide-required-asterisk :model="form" :rules="rules" label-width="80px">
        <el-form-item label="经营业务" prop="yewu">
          <el-checkbox-group v-model="form.yewu">
            <el-checkbox :label="k" v-for="(v, k) in yewu" :key="k" :style="{ display:  k === 'dj' ? 'block' : 'inline-block'}">{{v}}</el-checkbox>
          </el-checkbox-group>
          <div style="font-size: 13px; line-height: 22px; color: red; margin-top: 2px; margin-bottom: 3px">
            注意：新添加【微短剧】业务，请选择是否经营该业务！
          </div>
          <p style="font-size: 13px; line-height: 22px; color: #666;">指与影视文化产业关联的产业所对应的产品。</p>
          <p style="font-size: 13px; line-height: 22px; color: #666;">关联产业指与影视文化产业关联的产业，如第三产业中的旅游业、餐饮业、交通运输业、文化和娱乐业、计算机服务和软件业、房地产业等，第二产业中的家具、服装、玩偶、食品饮料、家电、图书与影像品、汽车等制造业。</p>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="updateYewu">确 定</el-button>
      </span>
    </el-dialog>
  </loading-content>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import yewu from '../utils/yewu'

export default {
  data () {
    return {
      yewu,
      yewuDialog: false,
      yewuShowClose: false,
      form: { yewu: [] },
      rules: { yewu: { required: true, message: '请选择经营业务，可多选' } }
    }
  },
  computed: {
    ...mapState('account', ['user']),
    ...mapState('myProgress', ['list', 'loading', 'error', 'page', 'query'])
  },
  methods: {
    ...mapActions('myProgress', ['search', 'changeCurrent', 'changeSize']),
    ...mapActions('account', ['upsertSample']),
    goReport (tableId, roundId, reportId) {
      this.$router.push(`/report?tableId=${tableId}&roundId=${roundId}&reportId=${reportId || 0}`)
    },
    updateYewu () {
      this.$refs.form.validate((valid) => {
        if (!valid) return
        this.upsertSample({ ...this.form })
          .then(() => {
            this.$message({ type: 'success', message: '保存成功', showClose: true })
            this.yewuDialog = false
          })
      })
    }
  },
  mounted () {
    this.search()
    this.form.yewu = this.user.sample.yewu
    this.yewuShowClose = this.user.sample.yewu.length > 0
    this.yewuDialog = !(this.user.sample.yewu.length > 0 && this.user.sample.yewu.indexOf('dj') >= 0)
    // if (this.user.sample.yewu.length === 0) {
    // this.yewuDialog = true
    // }
  }
}
</script>
<style lang="scss" scoped>
  .company {
    line-height: 1.6em;
    div { margin-bottom: 5px; }
    .name { font-size: 16px; }
    .code { color: #555; }
    .lb { color: #555}
  }
  .report-title {
    display: flex; justify-content: center; align-items: center;
    .t { flex: 1; }
  }
</style>
