<template>
  <el-card>
    <el-collapse v-model="opens">
      <el-collapse-item :name="item.name" :key="item.name" v-for="item in list">
        <div slot="title" :class="{ marked: item.mark }">
          {{ item.title }}
        </div>
        <div v-for="(p, index) in item.desc" :key="index">{{p}}</div>
      </el-collapse-item>
    </el-collapse>
  </el-card>
</template>
<script>
export default {
  data () {
    return {
      opens: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14'],
      list: [
        { name: '1', title: '组织机构代码', desc: ['指根据中华人民共和国国家标准《全国组织机构代码编制规则》（GB11714-1997），由组织机构代码登记主管部门给每个企业、事业单位、机关、社会团体和民办非企业等单位颁发的在全国范围内唯一的、始终不变的法定代码。组织机构代码共9位，无论是法人单位还是产业活动单位，组织机构代码均由8位无属性的数字和1位校验码组成。'] },
        { name: '2', title: '单位详细名称', desc: ['指经有关部门批准正式使用的单位全称。所有单位均需填写本项。'] },
        { name: '3', title: '企业职工数量', desc: ['指报告期（季度）末现有的与企业订立劳动合同的所有人员（含全职、兼职和临时职工，也包括虽未与企业订立劳动合同但由企业正式任命的人员）的数量。'] },
        { name: '4', title: '出品或联合出品影视作品数量', mark: true, desc: ['指报告期（季度）内企业出品或联合出品影视作品的数量。'] },
        { name: '5', title: '发行或联合发行影视作品数量', mark: true, desc: ['指报告期（季度）内企业发行或联合发行影视作品的数量。'] },
        { name: '6', title: '影视科技活动人员数量', desc: ['指报告期（季度）末企业现有的从事影视科技活动人员的数量。', '其中，影视科技活动人员是指从事影视科学研究、技术开发、内容制播、运行维护、工程设计、科技普及、教学和人才培养、科技管理等工作，或在促进影视科技与经济结合第一线工作的科技工作者，且累计从事影视科技活动的实际工作时间占全年制度工作时间10%以上的人员。'] },
        {
          name: '7',
          title: '影视版权拥有量',
          desc: [
            '指报告期（季度）末企业现有的影视著作权总量。',
            '影视版权指影视作品的个人作者或者公司对其作为制片人所依法享有的人身权和财产权。含电影发行权、电影放映权、信息网络传播权、复制权、发表权、署名权、修改权、保护作品完整权、出租权、展览权、表演权、放映权、广播权、摄制权、改编权、翻译权、汇编权以及应当由著作权人享有的其他权利。'
          ]
        },
        { name: '8', title: '专利拥有量', desc: ['指报告期（季度）末企业现有的、经国内外知识产权行政部门授权且在有效期内的专利总件数，包括发明、实用新型、外观设计三种专利授权数的总和。'] },
        { name: '9', title: '影视产品和服务出口额', desc: ['指截至报告期（季度）末企业的影视产品（电影、电视剧与动漫等）和服务（影视作品境外落地的集成与播出服务、对外工程承包服务、对外设计与监理服务等）于本年度累计出口到国外的总金额。'] },
        { name: '10', title: '制作成本', desc: ['指报告期（季度）内影视文化企业的人员成本（包含演员片酬、导演片酬及其他人员劳务费用等成本）、设备成本、交通成本、美术成本及特效成本等的总和。'] },
        { name: '11', title: '服务需求量', desc: ['指报告期（季度）内企业需要的影视文化相关服务量，其中，服务主要包括咨询、经纪人、服装道具、表演培训等。'] },
        { name: '12', title: '资金回笼率', desc: ['指用于衡量报告期（季度）内资金回收情况的指标，其计算公式为，资金回笼率=（已收回款+预收款）÷（销售总额+预付款）×100%。'] },
        { name: '13', title: '有形关联产品需求量', desc: ['指对由版权所有者开发或者通过相关授权开发的、与影视作品内容有关的且以实物为载体的产品需求量。'] },
        { name: '14', title: '关联产业产品', desc: ['指与影视文化产业关联的产业所对应的产品。', '关联产业指与影视文化产业关联的产业，如第三产业中的旅游业、餐饮业、交通运输业、文化和娱乐业、计算机服务和软件业、房地产业等，第二产业中的家具、服装、玩偶、食品饮料、家电、图书与影像品、汽车等制造业。'] }
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
.marked { background-color: #ffff00; line-height: 2em; }
</style>
